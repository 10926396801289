'use client'

import {
  AriaAttributes,
  AriaRole,
  CSSProperties,
  ForwardRefExoticComponent,
  ImgHTMLAttributes,
  Ref,
  RefAttributes,
  SyntheticEvent,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import classNames from 'classnames/bind'

import styles from './Image.scss'
import { getTestId } from '../../utils/testId'
import { MediaSize } from '../types'
import { noop } from '../../utils/noop'
import { mergeRefs } from '../../utils/mutableRefs'

enum Scaling {
  Contain = 'contain',
  Cover = 'cover',
  Fill = 'fill',
  ScaleDown = 'scale-down',
}

enum Ratio {
  Square = 'square',
  Fullscreen = 'fullscreen',
  Portrait = 'portrait',
  SmallPortrait = 'small-portrait',
  Landscape = 'landscape',
  SmallLandscape = 'small-landscape',
}

enum Styling {
  Rounded = 'rounded',
  Circle = 'circle',
}

enum LabelStyle {
  Tight = 'tight',
  Narrow = 'narrow',
  Regular = 'regular',
  Wide = 'wide',
}

export type Props = {
  src?: string | null
  fallbackSrc?: string | null
  /**
   * Sets the background color on an image element.
   * Accepts any legal CSS color values (Hexadecimal, RGB, predefined names etc.).
   */
  color?: string | null
  alt?: string
  srcset?: string
  sizes?: string
  label?: string
  size?: MediaSize | `${MediaSize}`
  scaling?: Scaling | `${Scaling}`
  ratio?: Ratio | `${Ratio}`
  styling?: Styling | `${Styling}`
  labelStyle?: LabelStyle | `${LabelStyle}`
  draggable?: boolean
  /**
   * Tells the browser how to load the image.
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-loading mdn/img#attr-loading}
   */
  loading?: ImgHTMLAttributes<HTMLImageElement>['loading']
  /** onLoad won't fire after page refresh due to SSR */
  onLoad?: (event: SyntheticEvent<HTMLImageElement>) => void
  role?: AriaRole
  aria?: AriaAttributes
  /**
   * Adds data-testid attribute to the parent and children components.
   * When used, --img and --label suffixes applied accordingly.
   */
  testId?: string
  /**
   * Sets the reference to the img element in DOM.
   */
  forwardedRef?: Ref<HTMLImageElement>
  /** onError won't fire after page refresh due to SSR */
  onError?: (event: SyntheticEvent<HTMLImageElement>) => void
}

const cssClasses = classNames.bind(styles)

const Image = ({
  color,
  src,
  fallbackSrc,
  ratio,
  scaling,
  size,
  styling,
  labelStyle,
  testId,
  label,
  loading,
  role,
  srcset,
  alt,
  draggable,
  onLoad,
  sizes,
  forwardedRef,
  aria,
  onError = noop,
}: Props) => {
  const ref = useRef<HTMLImageElement>(null)

  const isScaled = [ratio, scaling, size].some(Boolean)
  const imageStyle: CSSProperties = color ? { backgroundColor: color } : {}
  const imageClass = cssClasses('image', size, scaling, ratio, styling, {
    [`label-${labelStyle || ''}`]: labelStyle,
    scaled: isScaled,
    cover: (size || ratio) && !scaling,
    ratio,
  })

  useEffect(() => {
    if (!ref.current) return

    const { complete, naturalHeight } = ref.current
    const errorLoadingImgBeforeHydration = complete && naturalHeight === 0

    if (errorLoadingImgBeforeHydration && fallbackSrc) {
      ref.current.src = fallbackSrc
    }
  }, [fallbackSrc])

  const handleError = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      onError(event)

      const target = event.target as HTMLImageElement

      if (fallbackSrc) {
        target.src = fallbackSrc
      }
    },
    [fallbackSrc, onError],
  )

  const imageSrc = src || fallbackSrc

  return (
    <div className={imageClass} style={imageStyle} data-testid={testId}>
      {imageSrc ? (
        <img
          role={role}
          src={imageSrc}
          srcSet={srcset}
          alt={alt || ''}
          className={styles.content}
          draggable={draggable}
          onLoad={onLoad}
          onError={handleError}
          data-testid={getTestId(testId, 'img')}
          sizes={sizes}
          loading={loading}
          ref={mergeRefs(forwardedRef, ref)}
          {...aria}
        />
      ) : null}
      {label ? (
        <div className={styles.label} data-testid={getTestId(testId, 'label')}>
          {label}
        </div>
      ) : null}
    </div>
  )
}

interface RefComponent extends ForwardRefExoticComponent<Props & RefAttributes<HTMLImageElement>> {
  Size: typeof MediaSize
  Scaling: typeof Scaling
  Ratio: typeof Ratio
  Styling: typeof Styling
  LabelStyle: typeof LabelStyle
}

// BUG: default exports do not auto-generate storybook ArgsTable
// https://github.com/storybookjs/storybook/issues/9511
// remove export when resolved
export const ImageWithForwardedRef = forwardRef<HTMLImageElement, Props>((props, ref) => (
  <Image forwardedRef={ref} {...props} />
)) as RefComponent

ImageWithForwardedRef.Size = MediaSize
ImageWithForwardedRef.Scaling = Scaling
ImageWithForwardedRef.Ratio = Ratio
ImageWithForwardedRef.Styling = Styling
ImageWithForwardedRef.LabelStyle = LabelStyle
ImageWithForwardedRef.displayName = Image.name

export default ImageWithForwardedRef
